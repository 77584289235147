import { useContext, useEffect, useState } from "react";

import { Route, Switch } from "react-router-dom";

import "../../components/styles/Dashboard.css";

import Sidebar from "components/SideBar";
import TopNav from "components/TopNav";
import Admin from "./Admin";
import WorkOrders from "./WorkOrders";
import Organisation from "./Organisation";

import AuthContext from "context/AuthContext";

export type currentUser = {
  uid?: string;
  displayName?: string;
  email?: string;
};

const Layout = ({ setIsUserLoginTrueORFalse }) => {
  // const [currentUser, setCurrentUser] = useState<currentUser>();
  const [currentUserDetails, setCurrentUserDetails] = useState<any>();
  // const [openMenu, setOpenMenu] = useState(true);
  const { auth } = useContext(AuthContext);
  const currentUser = auth as currentUser;

  const [collapse, setCollapse] = useState(true);

  return (
    <div>
      <Switch>
        <Route exact path="/work-orders/map/:workOrderId">
          <section className="mapPageWrapper">
            <p className="text-center copyright">Copyright © Eningo 2024</p>
          </section>
        </Route>
        <Route path="/">
          <section className="wrapper">
            <Sidebar
              userDetails={{
                displayName: currentUser?.displayName,
                email: currentUser?.email,
              }}
              collapse={collapse}
              setCollapse={setCollapse}
              setIsUserLoginTrueORFalse={setIsUserLoginTrueORFalse}
            />
            <main
              className={`page-content ${collapse && "collapsePageContent"}`}
            >
              <TopNav currentUserDetails={currentUserDetails} />
              <div className="pageTemplate">
                <section className="content">
                  <Route exact path="/oversikt">
                    <Admin />
                  </Route>
                  <Route exact path="/">
                    <WorkOrders
                      setIsUserLoginTrueORFalse={setIsUserLoginTrueORFalse}
                    />
                  </Route>
                  <Route exact path="/organisation">
                    <Organisation />
                  </Route>
                </section>
                <p className="text-center copyright">Copyright © Eningo 2024</p>
              </div>
            </main>
          </section>
        </Route>
      </Switch>
    </div>
  );
};

export default Layout;
