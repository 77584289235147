import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';


interface PopupProps {
  message: string;
  onHide: () => void;
}

const PopupContainer = styled.div`
  position: fixed;
  gap: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #06B4C8;
  border-radius: 10px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #FFFFFF;
`;

const PopupSuccessfulMessage: React.FC<PopupProps> = ({ message, onHide }) => {
  return (
    <PopupContainer>
      <p style={{  margin: "0px"}}>{message}</p>
      <CloseButton onClick={onHide}>
        <FaTimes />
      </CloseButton>
    </PopupContainer>
  );
};

export default PopupSuccessfulMessage;
