import { keyframes } from "styled-components";

export const ViewEntranceAnimation = keyframes`
    from {
        transform: scale(0.9);
    }  
    to {
        transform: scale(1);
    }
`;

export const PopupEntranceAnimation = keyframes`
    from { 
        right: -22%; 
    }
    to { 
        right: 10px; 
    }
`;

export const SlideAnimation = keyframes`
    from {
        transform: translate(0px, 50%);
    }
    to {
        transform: translate(0px, 0px);
    }
`;

export const ScaleAnimation = keyframes`
    from { 
        transform: scale(0.7); 
    }
    to { 
        transform: scale(1); 
    }    
`;

export const ConfirmationPopupAnimation = keyframes`
    from { 
        transform: scale(0.85); 
    }
    to { 
        transform: scale(1); 
    }
`;

export const FadeAnimation = keyframes`
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
`;

export const ItemContainerAnimation = keyframes`
    from {
        transform: translate(0px, -10px);
        opacity: 0;
    }
    to {
        transform: translate(0px, 0px);
        opacity: 1;
    }
`;

export const VerticalExpand = keyframes`
    0% {
        margin-top: -100%;
        opacity: 0;
    }
    1% {
        opacity: 1;
    }
    100% {
        margin-top: 0px;
        opacity: 1;
        height: 300px;
    }
`;
