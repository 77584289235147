import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
	VerticalFlex,
	HorizontalFlex,
	Form,
	SpaceBetweenRow,
	Image,
	IconButton,
} from "../pandora/styled";
import {
	PrefixIcon,
	SearchInput,
	SearchInputContainer,
} from "./styles/nav-style";
import Search from "resources/images/svg/search.svg";
import Download from "resources/images/svg/download-file.svg";
import Pdf from "resources/images/svg/pdf.svg";
import Dxf from "resources/images/svg/dxf.svg";
import { darkGreen, veryLightGrey } from "resources/newColors";

export interface DisplayFilesProps {
	selectedUserFiles: string[] | undefined;
	setError: (b: boolean) => void;
	setErrorMessage: (s: string) => void;
	handleFileClick: (s: string) => void;
	caseID: string;
}

export default function DisplayFiles({
	selectedUserFiles,
	setError,
	setErrorMessage,
	handleFileClick,
	caseID
}: DisplayFilesProps) {
	const [searchValue, setSearchValue] = useState<string>();
	const _onSearch = (e) => { };
	const _onChange = (e) => {
		const value = e.target.value;
		setSearchValue(value);
	};
	return (
		<Container
			style={{
				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",
				alignSelf: "center",
			}}
			fluid
		>
			<VerticalFlex css="width: 100%; margin: 10px 0">
				{selectedUserFiles && selectedUserFiles.length ? selectedUserFiles.map((item: string) => <HorizontalFlex
					css={`
						border-top: 0px solid ${veryLightGrey};
					`}
				>
					<Row
						style={{
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
							paddingTop: "30px",
						}}
					>
						<Col
							style={{
								fontWeight: "bold",
							}}
						>
							<SpaceBetweenRow
								css={`
												border: 0.5px solid ${darkGreen};
												padding: 10px;
											`}
							>
								<HorizontalFlex css="flex: 1">
									<Image
										width={"30px"}
										src={item.includes("pdf") ? Pdf : Dxf}
									/>
								</HorizontalFlex>
								<HorizontalFlex css="flex: 8">
									{item.split('/').pop()}
								</HorizontalFlex>
								<IconButton
									width={20}
									onClick={() => {
										if (item) {
											handleFileClick(item);
										} else {
											setError(true);
											setErrorMessage("File Not Found!");
										}
									}}
									src={Download}
								/>
							</SpaceBetweenRow>
						</Col>
					</Row>
				</HorizontalFlex>) : (
					<HorizontalFlex css={"font-size: 11px;"}>Inga filer</HorizontalFlex>
				)}
			</VerticalFlex>
		</Container>
	);
}

