import { imageData } from "DTO/userWorkOrderListDto";
import { HorizontalFlex } from "../pandora/styled";

export type Image = {
	selectedUserImage: { url: string }[];
};

export interface DisplayImagesProps {
	setIsOpen: (b: boolean) => void;
	setImageIndex: (n: number) => void;
	imageProps: imageData[] | [];
}

export default function DisplayImages({
	setIsOpen,
	setImageIndex,
	imageProps,
}: DisplayImagesProps) {
	return (
		<HorizontalFlex
			css={"flexWrap: wrap; justify-content: space-between; overflow-x: scroll"}
		>
			{imageProps && imageProps?.length ? (
				imageProps?.map((data, index) => {
					const { url } = data;
					return (
						<HorizontalFlex
							key={index}
							onClick={() => {
								setIsOpen(true);
								setImageIndex(index);
							}}
							css="cursor: pointer"
						>
							<img
								src={
									url
										? url
										: `https://img.icons8.com/pastel-glyph/64/000000/error.png`
								}
								style={{
									margin: 5,
									maxHeight: 400,
									width: "auto",
								}}
								alt=""
							/>
						</HorizontalFlex>
					);
				})
			) : (
				<HorizontalFlex css={"font-size: 11px;"}>Inga bilder</HorizontalFlex>
			)}
		</HorizontalFlex>
	);
}
