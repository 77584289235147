export const lightGrey: string = "rgb(44, 50, 58)";
export const extraLightGrey: string = "rgb(88, 100, 116)";

export const green: string = "rgb(25, 139, 116)";
export const lightGreen: string = "rgb(49, 165, 141)";

export const blue: string = "rgb(92, 125, 204)";
export const lightBlue: string = "rgb(119, 146, 212)";

export const red: string = "rgb(255, 80, 80)";
export const lightRed: string = "rgb(255, 102, 102)";

export const purple: string = "rgb(153, 102, 255)";
export const lightPurple: string = "rgb(170, 128, 255)";

export const yellow: string = "rgb(252, 173, 3)";

export const darkGrey = (alpha?: number): string => {
  return !alpha ? "rgb(22, 25, 29)" : `rgba(22, 25, 29, ${alpha})`;
};

export const alpha = (color: string, alpha: number): string => {
  const c: string = color.substring(
    color.lastIndexOf("(") + 1,
    color.lastIndexOf(")")
  );
  return `rgba(${c}, ${alpha})`;
};
