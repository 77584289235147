import Modal from "react-modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Cross from "resources/images/svg/cross-white.svg";
import { Carousel } from "react-responsive-carousel";
import { HorizontalFlex, Sect, VerticalFlex } from "../pandora/styled";
import { darkGreen } from "resources/newColors";

export default function ImageSlider({
	modalIsOpen,
	afterOpenModal,
	closeModal,
	customStyles,
	imageIndex,
	selectedUserImage,
	downloadImages,
}) {
	Modal.setAppElement("body");
	return (
		<Modal
			isOpen={modalIsOpen}
			onAfterOpen={afterOpenModal}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Images"
		>
			<VerticalFlex
				css={`
					position: relative;
					background-color: ${darkGreen};
					padding: 20px;
				`}
			>
				<HorizontalFlex css="justify-content: flex-end;">
					<Sect
						css={`
							justify-content: flex-end;
							cursor: pointer;
							position: absolute;
							background-color: ${darkGreen};
							padding: 10px 20px;
							top: -25px;
							right: 0;
							z-index: 100;
						`}
						onClick={() => {
							closeModal();
						}}
					>
						<img style={{ height: 20, width: "auto" }} src={Cross} alt="" />
					</Sect>
				</HorizontalFlex>
				<Carousel
					showArrows={true}
					selectedItem={imageIndex}
					emulateTouch
					showThumbs={false}
					width={896}
					useKeyboardArrows={true}
					autoPlay={false}
				>
					{selectedUserImage &&
						selectedUserImage.map((data, index) => {
							const { url } = data;
							return (
								<div>
									<img
										key={url}
										src={
											url
												? url
												: `https://img.icons8.com/pastel-glyph/64/000000/error.png`
										}
										style={{
											height: 504,
											width: "auto",
										}}
										alt=""
									/>
									<a
										className="legend"
										style={{ cursor: "pointer" }}
										download
										onClick={() => downloadImages(url, index)}
									>
										<i className="fa fa-download" />
										Download
									</a>
								</div>
							);
						})}
				</Carousel>
			</VerticalFlex>
		</Modal>
	);
}
