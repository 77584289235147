import { HorizontalFlex } from "../pandora/styled";
import { Alert as BootstrapAlert } from "react-bootstrap";

export interface AlertProps {
	showError: boolean;
	errorMessage: string;
	setError: (b: boolean) => void;
	setErrorMessage: (s: string) => void;
}

export default function Alert({
	showError,
	errorMessage,
	setError,
	setErrorMessage,
}: AlertProps) {
	return (
		<BootstrapAlert
			show={showError}
			variant="danger"
			onClose={() => {
				setError(false);
				setErrorMessage("");
			}}
			dismissible
		>
			<BootstrapAlert.Heading>Oh snap!</BootstrapAlert.Heading>
			<HorizontalFlex>{errorMessage}</HorizontalFlex>
		</BootstrapAlert>
	);
}
