import AuthContext from "context/AuthContext";
import { HorizontalFlex, VerticalFlex, Sect } from "pandora/styled";
import { useState, useEffect, useContext } from "react";
import UploadImageToS3 from "./UploadImageToS3";


export default function Organisation() {

	const [tenantName, setTenantName] = useState("");
	const { auth } = useContext(AuthContext)
	
	return (
	<VerticalFlex>
		<HorizontalFlex>
			<Sect css="font-size: 24px; font-weight: 600" >
				{ tenantName }
				<UploadImageToS3/>
			</Sect>
		</HorizontalFlex>
	</VerticalFlex>
	);
}
