import styled from "styled-components";
import { Link } from "react-router-dom";
import {
	ScaleAnimation,
	ItemContainerAnimation,
} from "../../pandora/animations";
import { darkGreen } from "resources/newColors";

export const Aside = styled("aside")<{ collapse: boolean; css?: string }>`
	box-sozing: border-box;
	display: flex;
	flex-direction: column;
	width: ${(props) => (props.collapse ? "80" : "200")}px;
	height: 100%;
	padding-top: 20px;
	box-sizing: border-box;
	position: fixed;
	top: 0;
	left: 0;
	background-color: ${darkGreen};
	overflow: hidden;
	z-index: 999;
	transition: width 500ms;
	${(props) => props.css ?? ""}
`;

export const ButtonContainer = styled(Link)<{
	collapse: boolean;
	css?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 0;
	border-bottom: ${(props) =>
		props.collapse ? "none" : "thin solid rgba(255, 255, 255, 0.29)"};
	transition: 0.3s linear;
	animation: ${ItemContainerAnimation} 0.3s 1;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	${(props) => props.css ?? ""}
`;

export const SidebarButtonContainer = styled(Link)<{
	collapse: boolean;
	hideBorder?: boolean;
	css?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: ${(props) =>
		props.collapse || props.hideBorder
			? "none"
			: "thin solid rgba(255, 255, 255, 0.29)"};
	transition: 0.3s linear;
	animation: ${ItemContainerAnimation} 0.3s 1;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	${(props) => props.css ?? ""}
`;

export const SidebarButtonSubContainer = styled("div")<{
	selected?: boolean;
	color?: string;
	css?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 40px;
	margin: 20px 0;
	background-color: ${(props) =>
		props.color
			? props.color
			: props.selected
			? "rgba(255, 255, 255, 0.29)"
			: "transparent"};
	padding: 10px;
	transition: 0.3s ease;
	animation: ${ScaleAnimation} 0.3s linear 1;
	${(props) => props.css ?? ""}
`;

export const SidebarButton = styled("img")<{ css?: string }>`
	height: auto;
	${(props) => props.css ?? ""}
`;

export const SidebarButtonLabelContainer = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: column;
	${(props) => props.css ?? ""}
`;

export const SidebarButtonLabel = styled("span")<{
	fontWeight?: number;
	padding?: number;
	fontSize?: number;
	color?: string;
	css?: string;
}>`
	color: ${(props) => props.color ?? "white"};
	font-size: ${(props) => props.fontSize ?? 13}px;
	font-weight: ${(props) => props.fontWeight ?? 900};
	padding: ${(props) => props.padding ?? 5}px;
	margin: 0 10px;
	min-width: 120px;
	${(props) => props.css ?? ""}
`;
