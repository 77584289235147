import ReactMapGl, { Layer, Marker, Source } from "react-map-gl";
import { useEffect, useState } from "react";
import IconTint from "react-icon-tint";
import { Button } from "@mui/material";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { useHistory } from "react-router";
import { imageData, userData } from "DTO/userWorkOrderListDto";
import { Spinner } from "react-bootstrap";
import { getWorkerOrderInfo } from "Services/Api";
import { handleBgColorMarker } from "providers/StylingFunc";

const swedenLat = 60.1282; // latitude of Sweden's center
const swedenLng = 14.2346; // longitude of Sweden's center

export default function Map({
  activeCaseId,
  // viewport,
  // mapApi,
  // setViewport,
  // setMapPopUp,
  // polygonFromCaseLocation,
  // polygonFromGeoJson,
  // tableData,
  // networkCoords,
  // networkUrls,
  // setSelectedUserData,
  // renderTooltip,
  // handleBgColorMarker,
  // selectedCaseId,
  showBigMap,
  show,
}) {
  const map_api = process.env.REACT_APP_MAPBOX_KEY;

  const [viewport, setViewport] = useState({
    latitude: swedenLat,
    longitude: swedenLng,
    zoom: 8,
    width: "100%",
    height: "45vh",
    margin: "auto",
  });
   const [networkCoords, setNetworkCoords] = useState([]);
   const [selectedUser, setSelectedUser] = useState<userData>();
  const [networkImages, setNetworkImages] = useState([]);
  const [polygonFromGeoJson, setPolygonFromGeoJson] = useState("");
  const [polygonFromCaseLocation, setPolygonFromCaseLocation] = useState<any>();
  const [markerCenter, setMarkerCenter] = useState({
    latitude: 0,
    longitude: 0,
  });
   const [selectedUserImage, setSelectedUserImage] = useState<
    imageData[] | []
  >();
  const [selectedUserFiles, setSelectedUserFiles] = useState<
    string[] | undefined
  >();
    const [caseLogs, setCaseLogs] = useState([]);
    const [mapPopUp, setMapPopUp] = useState(false);
    const history = useHistory();
    const [loadingTab, setLoadingTab] = useState(false);
  const [hideMarker, setHideMarker] = useState(true);
  const [alignment, setAlignment] = useState("mapbox://styles/selvincephusj/cknnge2gr46ef17ny95fk8yt7");

  const handleChange = (name) => {
    setAlignment(name);
  };

  useEffect(() => {
    setSelectedUserData()
}, [activeCaseId])
  

  const setSelectedUserData = async () => {
    setLoadingTab(true)
    setSelectedUserImage([]);
    setSelectedUserFiles(undefined);
    //  setSelectedCaseId(activeCaseData);
    try {
    const images: any = await getWorkerOrderInfo(activeCaseId);
    setSelectedUser(images.Data)
    let allCaseLogs = images ? images?.Data?.Logs : [];
      let geomatry = images ? images?.Data?.Geometry : [];
      setPolygonFromCaseLocation(geomatry);
      setCaseLogs(allCaseLogs);
      const newViewport = {
        ...viewport,
        latitude: images?.Data?.Center?.WGS84[0][0],
        longitude: images?.Data?.Center?.WGS84[0][1],
        zoom: 17,
      };
      setViewport(newViewport);
      setMarkerCenter({
        latitude: images?.Data?.Center?.WGS84[0][0],
        longitude: images?.Data?.Center?.WGS84[0][1],
      });
      if (images && images?.Data && images?.Data && images?.Data?.network_coords) {
        setNetworkCoords(images?.Data?.network_coords);
      }
      if (images && images?.Data && images?.Data && images?.Data?.network_urls) {
        setNetworkImages(images?.Data?.network_urls);
      }
      if (images?.Data?.Document) {
        setSelectedUserFiles(images?.Data?.Document);
      } else {
        setSelectedUserFiles(undefined);
      }
      if (images?.Data?.ImageData?.length) {
        setSelectedUserImage(images?.Data?.ImageData);
      } else {
        setSelectedUserImage([]);
      }
      setTimeout(() => {
        setLoadingTab(false);
      }, 2500);
    } catch (e: any) {
      if (e?.response?.status === 401) {
        localStorage.removeItem("token");
        history.push("/");
      }
      setSelectedUserImage([]);
      setSelectedUserFiles(undefined);
    }
  };

  return (
    <>
    {!loadingTab ?
      <ReactMapGl
        {...viewport}
        mapboxApiAccessToken={map_api}
        onViewportChange={(newViewport) => {
          setViewport(newViewport);
          if (newViewport.zoom < 15) {
            setHideMarker(false);
          }
          if (newViewport.zoom > 16) {
            setHideMarker(true);
          }
          setMapPopUp(false);
        }}
        mapStyle={alignment}
        style={{
          margin: "auto",
          minWidth: 100,
          maxWidth: "100%",
        }}
      >
        <div className="mapViewBtn">
          <button
            type="button"
            className={
              alignment === "mapbox://styles/selvincephusj/cknnge2gr46ef17ny95fk8yt7" ? "btn activeMap" : "btn"
            }
            onClick={() => handleChange("mapbox://styles/selvincephusj/cknnge2gr46ef17ny95fk8yt7" )}
          >
            Flygfoto
          </button>
          <button
            type="button"
            className={alignment === "mapbox://styles/mapbox/streets-v12" ? "btn activeMap" : "btn"}
            onClick={() => handleChange("mapbox://styles/mapbox/streets-v12")}
          >
            Karta
          </button>
        </div>

        {!show && (
          <Button onClick={() => showBigMap(!show)} className="zoomMapIcon">
            <CropFreeIcon />
          </Button>
        )}

        <div key={polygonFromCaseLocation}>
          {networkCoords &&
            Object.keys(networkCoords).map(([key]) => {
              if (networkImages[key] && networkCoords[key]) {
                return (
                  <div key={networkImages[key]}>
                    <Source
                      url={networkImages[key]}
                      coordinates={networkCoords[key]}
                      type={"image"}
                      id={networkImages[key]}
                    >
                      <Layer
                        type={"raster"}
                        paint={{}}
                        id={networkImages[key]}
                        key={networkCoords[key]}
                      />
                    </Source>
                  </div>
                );
              } else {
                <div />;
              }
            })}
        </div>
        {polygonFromCaseLocation && (
          <div>
            <Source id="data" type="geojson" data={polygonFromCaseLocation} />
            <Layer
              id={"new"}
              type="fill"
              paint={{
                "fill-color": "red",
                "fill-opacity": 0.3,
              }}
              source={"data"}
            />
          </div>
        )}

        {polygonFromGeoJson && (
          <div>
            <Source id="data2" type="geojson" data={polygonFromGeoJson} />
            <Layer
              id={"new2"}
              type="line"
              paint={{
                "line-color": "blue",
                "line-width": 3,
              }}
              source={"data2"}
            />
          </div>
        )}

        {selectedUser?.Center?.WGS84 &&
          selectedUser?.Center?.WGS84.map((data) => {
            return (
              <Marker
                longitude={data[1]}
                latitude={data[0]}
                offsetLeft={-25}
                offsetTop={-40}
              >
                <div>
                  <IconTint
                    src="https://img.icons8.com/material-sharp/48/000000/give-way--v1.png"
                    color={handleBgColorMarker(selectedUser?.Status)}
                  />
                </div>
              </Marker>
            );
          })}
            </ReactMapGl> : <div className="d-flex justify-content-center align-items-center" style={{minHeight: "250px"}}> <Spinner
                 style={{ margin: "auto" }}
                 variant="dark"
                 animation="border"
                 role="status"
               ></Spinner>  </div>}
    </>
  );
}
