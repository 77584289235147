import { createContext, ReactNode } from "react";

export interface AuthValue {
    [x: string]: ReactNode;
    name: string,
    profile_pic: string,
    uid?: string,
    org: string
}

export interface AuthContextInterface {
    auth?: AuthValue,
    setAuth?: any,
    logout?: any,
}

const defaultValue:AuthContextInterface = {}

const AuthContext = createContext<AuthContextInterface>(defaultValue)

export default AuthContext