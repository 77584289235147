import styled from "styled-components";
import { Link } from "react-router-dom";

import {
	green,
	extraLightGrey,
	lightGreen,
	darkGrey,
	red,
} from "../resources/colors";
import { ScaleAnimation, ItemContainerAnimation } from "./animations";

export const Flex = styled("div")<{ direction: string; css?: string }>`
	display: flex;
	flex-direction: ${(props) => props.direction};
	height: 100%;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const Sect = styled("span")<{ css?: string }>`
	display: inline-flex;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const HorizontalFlex = styled("div")<{ css?: string }>`
	display: flex;
	width: auto;
	height: auto;
	${(props) => props.css ?? ""}
`;

export const VerticalFlex = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: column;
	${(props) => props.css ?? ""}
`;

export const ShadowLayer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	background: ${darkGrey(0.5)};
`;

export const SaveButton = styled("div")<{ css?: string }>`
	position: absolute;
	width: fit-content;
	height: 25px;
	border-radius: 5px;

	top: 20px;
	right: 20px;

	padding: 5px;
	padding-left: 15px;
	padding-right: 15px;

	background-color: ${green};

	font-size: 12px;
	font-weight: bold;
	color: white;
	line-height: 25px;

	user-select: none;
	cursor: pointer;
	z-index: 5;
	transition: 0.3s ease;

	animation: ${ScaleAnimation} 0.2s linear 1;

	${(props) => props.css ?? ""}

	&: hover {
		background-color: ${lightGreen};
	}

	${(props) => props.css ?? ""}
`;

export const PageTitle = styled("h1")<{ fontSize?: number }>`
	font-size: ${(props) => props.fontSize ?? 35}px;
	color: white;
	text-align: left;
	margin: 0;
`;

export const PageIcon = styled("div")<{ icon: string; size?: number }>`
	width: ${(props) => props.size ?? 35}px;
	height: ${(props) => props.size ?? 35}px;
	margin-right: 20px;

	border-width: 0;
	outline: none;

	cursor: pointer;

	background-size: contain;
	mask: url(${(props) => props.icon}) no-repeat 50% 50%;
	mask-size: cover;

	background-color: white;
`;

export const Input = styled("input")<{
	focusBorder: boolean;
	borderColor?: string;
	fontSize?: number;
	css?: string;
}>`
	position: relative;
	margin: 0;
	margin-top: 5px;
	height: 45px;
	width: 100%;
	outline: none;
	border: 0px solid ${green};
	padding: 10px;
	border-radius: 5px;
	${(props) => (props.focusBorder ? "border-left: 3px solid transparent;" : "")}

	background: ${darkGrey()};

	text-align: left;
	color: white;
	font-size: ${(props) => props.fontSize ?? 11}px;
	font-family: Montserrat, sans-serif;
	font-weight: normal;

	box-sizing: border-box;

	transition: 0.3s ease;

	${(props) =>
		props.focusBorder
			? ` 
        &: focus {
            border-left: 3px solid ${props.borderColor ?? green};
        }
    `
			: ""}

	::placeholder {
		color: white;
		opacity: 50%;
		font-size: ${(props) => props.fontSize ?? 11}px;
	}

	${(props) => props.css ?? ""};
`;

export const Button = styled("button")<{
	color: string;
	hoverColor: string;
	css?: string;
}>`
	border-radius: 5px;

	color: white;
	line-height: 40px;
	font-size: 13px;
	font-weight: bold;

	user-select: none;
	cursor: pointer;

	background-color: ${(props) => props.color};

	background-position: center;
	transition: background 0.8s;

	&: hover {
		background: ${(props) => props.color}
			radial-gradient(circle, transparent 1%, ${(props) => props.color} 1%)
			center/15000%;
	}
	&: active {
		background-color: ${(props) => props.hoverColor};
		background-size: 100%;
		transition: background 0s;
	}

	${(props) => props.css ?? ""}
`;

export const MessageDiv = styled.div`
	position: relative;
	width: auto;
	height: fit-content;
	padding: 10px;
	margin-bottom: 20px;

	font-size: 12px;
	font-weight: bold;

	animation: ${ItemContainerAnimation} 0.3s 1;
`;

export const ErrorDiv = styled(MessageDiv)`
	background: rgba(255, 80, 80, 0.2);
	color: ${red};
	border: 1px solid ${red};
	border-radius: 5px;
`;

export const SuccessDiv = styled(MessageDiv)`
	background: rgba(25, 139, 116, 0.2);
	color: ${lightGreen};
	border: 1px solid ${green};
	border-radius: 5px;
`;

export const LinkButton = styled.div`
	background: transparent;
	margin-right: 10px;

	text-align: center;
	color: ${extraLightGrey};
	font-size: 12px;
	font-weight: normal;
	text-decoration: none;

	user-select: none;
	cursor: pointer;
	transition: 0.3s ease;

	&: hover {
		color: white;
	}
`;

export const ViewContainer = styled.div`
	position: relative;
	min-height: 100%;
	height: auto;
	overflow: auto;
`;

export const SpaceBetweenRow = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const CenterRow = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	${(props) => props.css ?? ""}
`;

export const Logo = styled("img")<{ css?: string }>`
	height: auto;
`;

export const Image = styled("img")<{
	height?: string;
	width?: string;
	css?: string;
}>`
	height: ${(props) => props.height ?? "auto"};
	width: ${(props) => props.width ?? "100%"};
	${(props) => props.css ?? ""}
`;

export const IconButton = styled("img")<{
	width?: number;
	css?: string;
}>`
	height: auto;
	width: ${(props) => props.width ?? 15}px;
	cursor: pointer;
	${(props) => props.css ?? ""}
`;

export const LogoContainer = styled(Link)`
	flex: 1;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const Form = styled("form")<{
	css?: string;
}>`
	${(props) => props.css ?? ""}
`;

export const Badge = styled("span")<{
	height?: number;
	width?: number;
	color?: string;
	css?: string;
}>`
	position: absolute;
	height: ${(props) => props.height ?? 5}px;
	width: ${(props) => props.width ?? 5}px;
	border-radius: 100%;
	background-color: ${(props) => props.color ?? lightGreen};
	${(props) => props.css ?? ""}
`;

export const ProButton = styled("button")<{
	color: string;
	hoverColor: string;
	css?: string;
}>`
	border: none;
	color: white;
	cursor: pointer;
	background-color: ${(props) => props.color};
	background-position: center;

	&: hover {
		background: ${(props) => props.hoverColor};
	}
	${(props) => props.css ?? ""}
`;