import styled from "styled-components";

import { Col, Row as BootstrapRow } from "react-bootstrap";
import { lightGrey, lightTurquoise, veryLightGrey } from "resources/newColors";

export const Table = styled("table") <{ css?: string }>`
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	${(props) => props.css ?? ""};
`;

export const Column = styled(Col) <{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const Row = styled(BootstrapRow) <{ css?: string }>`
	${(props) => props.css ?? ""};
`;

export const TableHead = styled("thead") <{ css?: string }>`
	text-align: left;
	box-shadow: 0px 1px ${veryLightGrey};
	${(props) => props.css ?? ""}
`;

export const TableRow = styled("tr") <{
	isActive?: boolean;
	header?: boolean;
	css?: string;
}>`
	display: flex;
	margin: 0 40px;
	padding: 5px;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 1px ${veryLightGrey};
	background-color: ${(props) =>
		props.isActive ? lightTurquoise : "transparent"};
	:hover {
		background-color: ${(props) =>
		props.header ? "transparent" : lightTurquoise};
	}
	${(props) => props.css ?? ""};
`;

export const HeaderSection = styled("th") <{ css?: string }>`
	flex: 1;
	font-size: 12px;
	font-weight: 600;
	color: ${lightGrey};
	${(props) => props.css ?? ""}
`;

export const TableBody = styled("tbody") <{ css?: string }>`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
	font-size: 11px;
	color: ${lightGrey};
	text-align: left;
	${(props) => props.css ?? ""}
`;

export const TableData = styled("td") <{ css?: string }>`
	flex: 1;
	background-color: transparent;
	${(props) => props.css ?? ""}
`;

export const Anchor = styled("a") <{
	hoverColor?: string;
	color?: string;
	textDecoration?: string;
	css?: string;
}>`
	color: ${(props) => props.color ?? lightGrey};
	text-decoration: ${(props) => props.textDecoration ?? "none"};
	:hover {
		text-decoration: ${(props) => props.textDecoration ?? "none"};
		color: ${(props) => props.hoverColor ?? lightGrey};
	}
	${(props) => props.css ?? ""}
`;

export const DropdownItems = styled("span") <{ show?: boolean; css?: string }>`
	display: ${(props) => (props.show ? "block" : "none")};
	position: absolute;
	border-radius: 0;
	top: -1px;
	left: -1px;
	right: -1px;
	border: 1px solid black;
	background-color: white;
	z-index: 100;
	${(props) => props.css ?? ""}
`;

export const DropdownButton = styled("button") <{ css?: string }>`
	display: flex;
	position: relative;
	border-radius: 0;
	align-items: center;
	background-color: transparent;
	border: thin solid ${veryLightGrey};
	padding: 3px;
	min-height: 25px;
	outline: none;
	${(props) => props.css ?? ""};
`;

export const DropdownItem = styled("span") <{ css?: string }>`
	display: flex;
	border-radius: 0;
	padding: 5px 10px;
	background-color: transparent;
	:hover {
		background-color: #eeeeee;
	}
	${(props) => props.css ?? ""}
`;

export const IconButton = styled("img") <{ css?: string }>`
	cursor: pointer;
	${(props) => props.css ?? ""}
`;

export const Navigation = styled("nav") <{ css?: string }>`
	display: flex;
	margin: 20px 40px;
	justify-content: flex-end;
	text-align: center;
	cursor: pointer;
	${(props) => props.css ?? ""}
`;

export const StatusCard = styled("div") <{
	color?: string;
	css?: string;
}>`
	display: inline-block;
	min-width: 80px;
	padding: 5px;
	text-align: left;
	text-transform: capitalize;
	color: ${(props) => props.color ?? "white"};
	${(props) => props.css ?? ""};
`;

export const SolveProjectButton = styled("div") <{ css?: string }>`
	display: flex;
	width: auto;
	height: auto;
	border: 1px solid #42DB89;
	${(props) => props.css ?? ""}
`;

export const UnSolveProjectButton = styled("div") <{ css?: string }>`
	display: flex;
	width: auto;
	height: auto;
	border: 1px solid #FF8383;
	${(props) => props.css ?? ""}
`;

export const Button = styled("button") <{ css?: string }>`
 border : 1px solid #06B4C8;
 border-radius: 10px;
 background-color: #06B4C8;
 color: #fff;
 font-size: 1rem;
 padding: 0.5rem 1rem;
 ${(props) => props.css ?? ""}
`;

export const FileUploadCard = styled("div") <{ css?: string }>`

${(props) => props.css ?? ""}
`;

export const Heading = styled("p") <{ css?: string }>`
font-size: 1.5rem;
padding: 0.5rem 1rem;
${(props) => props.css ?? ""}
`;

export const SuccessMessage = styled("p") <{ css?: string }>`
color: #ffffff;
${(props) => props.css ?? ""}
`;

export const FileSelectBox = styled("div") <{ css?: string }>`
${(props) => props.css ?? ""}
`;

export const FileUploadBox = styled("div") <{ css?: string }>`
${(props) => props.css ?? ""}
`;

export const SlectedFileInfoContainer = styled("div") <{ css?: string }>`
margin: 1rem;
text-align: left;
${(props) => props.css ?? ""}
`;

export const IconContainerBox = styled("div") <{ css?: string }>`
padding: 0.5rem 1.4rem;
border: 3px solid #06B4C8;
border-radius: 5px;
width: 70px;
height: 100px
color: #06B4C8;
margin-bottom: 10px;
${(props) => props.css ?? ""}
`;

export const ButtonLabel = styled("button") <{ css?: string }>`
${(props) => props.css ?? ""}
`;

export const PaginationDiv = styled("div") <{ css?: string }>`
	${(props) => props.css ?? ""};
	display: inline;
`;

export const PaginationItem = styled("div") <{
	css?: string
	isActive?: boolean;
	hoverColor?: boolean;
}>`
	margin-top: 14px;
	display: inline-block;
	color: black;
	font-family: 'Red Hat Display';
	font-weight: 700;
	font-size: 10px;
	line-height: 13px;
	text-align: center;
	padding: 8px 12px;
	text-decoration: none;
	transition: background-color .3s;
	${(props) => props.css ?? ""};
	background-color: ${(props) =>
		props.isActive ? "#01393F" : "transparent"};
	color: ${(props) =>
		props.isActive ? "#fff" : "#01393F"};
	:hover {
		background-color: ${(props) =>
		props.hoverColor ? "transparent" : "#ddd"};
	}
	${(props) => props.css ?? ""};
`;

export const DownloadButton = styled("button") <{ css?: string }>`
	${(props) => props.css ?? ""};
	border: none;
	background: none;
`;

export const FileLoader = styled("div") <{ css?: string }>`
border: 5px solid #f3f3f3; /* Light grey */
border-top: 5px solid #3498db; /* Blue */
border-radius: 50%;
width: 30px;
height: 30px;
animation: spin 2s linear infinite;
	${(props) => props.css ?? ""}
`;

export const ExButton = styled("button") <{ css?: string }>`
	${(props) => props.css ?? ""};
	font-size: 16px;
	border: none;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
`;
