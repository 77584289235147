import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { HorizontalFlex } from "../pandora/styled";
export default function DisplayLogs({ caseLogs }) {

	return (
		<>
			{caseLogs.length ? (
				<Container fluid>
					<Row
						style={{
							alignItems: "flex-start",
							justifyContent: "flex-start", 
						}}
					>
						<Col
							className="p-2 col-example text-left"
							style={{
								fontSize: "12px",
								fontWeight: "bold",
							}}
							xs={3}
						>
							Tid
						</Col>
						<Col
							className="p-2 col-example text-left"
							style={{
								fontSize: "12px",
								fontWeight: "bold", 
							}}
							xs={6}
						>
							Aktivitet
						</Col>
						<Col
							className="p-2 col-example text-left"
							style={{
								fontSize: "12px",
								fontWeight: "bold", 
							}}
							xs={2}
						>
							Ansvarig
						</Col>
					</Row>
					{caseLogs.map((data) => {
						return (
							<Row 
							>
								<Col
									style={{
										fontSize: "12px",
									}}
									xs={3} 
									className="p-2 col-example text-left"
								>
									{moment(data.Date).format("DD MMM YYYY HH:mm:ss")}
								</Col>
								<Col 
									style={{
										fontSize: "12px",
									}}
									xs={6}
									className="p-2 col-example text-left"
								>
									{data.Activity}
								</Col>
								<Col
								style={{
									fontSize: "12px",
								}}
								xs={3} 
									className="p-2 col-example text-left"
								>
									{data.User}
								</Col>
							</Row>
						);
					})}
				</Container>
			) : (
				<HorizontalFlex>No Logs</HorizontalFlex>
			)}
		</>
	);
}
