import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { lightGreen } from "resources/colors";
import { turquoise } from "resources/newColors";
import LogoIcon from "resources/images/svg/new-logo.svg";
import showPwdIcon from "resources/images/svg/show-password.svg";
import hidePwdIcon from "resources/images/svg/hide-password.svg";
import {parseJwt} from '../../Utils/Utils'
import {
	Input,
	Button,
	OverlayLayer,
	ErrorDiv,
	Logo,
	SpaceBetweenRow,
	Background,
	Container,
	LoginContainer,
	LogoContainer,
	SiteTitle,
	LoginSubContainer,
	HeaderText,
	InputContainer,
	ButtonContainer,
	InputLabel,
	ContactUsLink,
	SaveLoginsContainer,
	SaveLoginsInner,
	SaveLoginsOuter,
	CenterRow,
	PasswordInput,
	PasswordInputContainer,
	PostfixIcon,
	FooterLinkContainer,
	FooterLink,
} from "./styled";
import { getMetrics, userLogin } from "Services/Api";
import { checkIsEmail } from "Utils/Utils";
import styled from "styled-components";
import AuthContext from "context/AuthContext";
import { FileLoader } from "views/authenticated/styled";

const footerLinks = [
	{ label: "Eningo.se", link: "https://www.eningo.se" },
	{ label: "Kontakt", link: "https://www.eningo.se/contact" },
	{ label: "Användarvillkor", link: "https://storage.googleapis.com/eningo-public-files/Eningo%20privacy%20policy.pdf" },
];

export interface PasswordInputComponentProps {
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onKeyPress: React.KeyboardEventHandler;
	placeholder?: string;
	type: string;
	postfixIcon?: React.ReactElement | React.ReactNode;
}

export const PasswordInputComponent = ({
	type,
	placeholder,
	value,
	onChange,
	onKeyPress,
	postfixIcon,
}: PasswordInputComponentProps) => {
	return (
		<PasswordInputContainer>
			<PasswordInput
				type={type}
				placeholder={placeholder || ""}
				value={value}
				onChange={onChange}
				onKeyPress={onKeyPress}
			/>
			{postfixIcon ? postfixIcon : ""}
		</PasswordInputContainer>
	);
};

export interface LoginInputProps {
	label: string;
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onKeyPress: React.KeyboardEventHandler;
	toggleSaveLogins?: () => void;
	saveLogins?: boolean;
	placeholder?: string;
	type: string;
	postfixIcon?: React.ReactElement | React.ReactNode;
}

export const LoginInput = ({
	label,
	value,
	onChange,
	onKeyPress,
	placeholder,
	type,
	saveLogins,
	toggleSaveLogins,
	postfixIcon,
}: LoginInputProps) => {
	return (
		<InputContainer>
			{label === "Användarnamn" && <InputLabel>{label}</InputLabel>}
			{label === "Lösenord" && (
				<SpaceBetweenRow>
					<InputLabel>{label}</InputLabel>
				</SpaceBetweenRow>
			)}
			{label === "Lösenord" ? (
				<PasswordInputComponent
					type={type}
					placeholder={placeholder || ""}
					value={value}
					onChange={onChange}
					onKeyPress={onKeyPress}
					postfixIcon={postfixIcon}
				/>
			) : (
				<Input
					type={type}
					placeholder={placeholder || ""}
					value={value}
					onChange={onChange}
					onKeyPress={onKeyPress}
				/>
			)}
			{label === "Lösenord" && (
				<SaveLoginsContainer>
					<SaveLoginsOuter onClick={() => toggleSaveLogins()}>
						<SaveLoginsInner
							backgroundColor={saveLogins ? turquoise : "#fff"}
						/>
					</SaveLoginsOuter>
					Kom ihåg mig
				</SaveLoginsContainer>
			)}
		</InputContainer>
	);
};


const LoginView = ({setIsUserLoginTrueORFalse}): React.ReactElement => {
	const history = useHistory();

	// State
	const [email, setEmail] = useState<string | null>("");
	const [password, setPassword] = useState<string | null>("");
	const [error, setError] = useState<string | null>(null);
	const [storeLogins, setStoreLogins] = useState<boolean>(false);
	const [loginActive, setLoginActive] = useState<boolean>(false);
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const [loading, setLoading] = useState(false);
	// Actions

	const { auth, setAuth } = useContext(AuthContext)
	
	const getMetricsData = async () => {
		try {
		  const metricsData = await getMetrics();
		//   console.log(metricsData);
		  const n = metricsData.CableCount - metricsData.AssignedCount -
		  	metricsData.UnassignedCount - metricsData.IssueCount;
			localStorage.setItem('metrics', JSON.stringify({
			'TotalCount': metricsData.TotalCount,
			'UnassignedCount': metricsData.UnassignedCount,
			'AssignedCount': metricsData.AssignedCount,
			'IssueCount': metricsData.IssueCount,
			'ProjectCount': metricsData.ProjectCount,
			'CableCount': metricsData.CableCount,
			'DoneCount': n,
			'OngoingProjectCount': metricsData.OngoingProjectCount,
		  	}));
		} catch (e: any) {
		  if (e?.response?.status === 401) {
		  }
		}
	  };

	const login = async () => {
		// Check credentials
		setLoading(true);
		setError('');
		if (email === "" || password === "") {
			setError("Ett eller flera fält är tomma.");
			setLoading(false);
			return;
		}

		// if(!checkIsEmail(email)) {
		// 	setError("Ogiltig e-postadress");
		// 	setLoading(false);
		// 	return;
		// }


		// Attempt login

		setLoginActive(true);
		const credentials = { username: email, password: password, app: "office" };
		try{
			const userData = await userLogin(credentials);
			//console.log(userData);
			setAuth({
				name: userData.name,
				profile_pic: userData.profile_pic,
				org: userData.org,
			})
			localStorage.setItem('user_name', userData.name);
			localStorage.setItem('username', credentials.username);
			localStorage.setItem('tenant_id', userData.tenant_id);
			localStorage.setItem('profile_pic', userData.profile_pic);
			localStorage.setItem('user_org', userData.org);
			localStorage.setItem('filter', 'all');
			getMetricsData();
			if(userData) {
				setIsUserLoginTrueORFalse(userData.access_token);
				localStorage.setItem('token', userData.access_token);
				const abc = parseJwt(userData.access_token)
				//console.log(abc);
				const _auth = {
					...abc
				}

				setTimeout(()=>{
					localStorage.removeItem("token")
					setIsUserLoginTrueORFalse(false);
					history.push('/');
				}, abc.exp)
			}
		}
		catch(err) {
				setError("Ogiltig e-postadress eller ogiltigt lösenord");
		}
		setLoading(false);
	};

	const onKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			e.preventDefault();
			e.stopPropagation();
			login();
		}
	};

	return (
		<Background>
			<OverlayLayer>
				<Container>
					<LoginContainer>
						<LogoContainer>
							<Logo alt="logo" src={LogoIcon} />
							<SiteTitle>Eningo</SiteTitle>
						</LogoContainer>
						<LoginSubContainer>
							<HeaderText>Logga in</HeaderText>
							{error && <ErrorDiv>{error}</ErrorDiv>}
							<LoginInput
								label="Användarnamn"
								type="email"
								placeholder="Ange användarnamn"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								onKeyPress={onKeyPress}
							/>
							<LoginInput
								label="Lösenord"
								type={isRevealPwd ? "text" : "password"}
								placeholder="Ange lösenord"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								onKeyPress={onKeyPress}
								toggleSaveLogins={() => setStoreLogins(!storeLogins)}
								saveLogins={storeLogins}
								postfixIcon={
									<PostfixIcon
										css={"width: 17px; height: auto"}
										title={isRevealPwd ? "Dölj lösenord" : "Visa lösenord"}
										src={isRevealPwd ? hidePwdIcon : showPwdIcon}
										onClick={() => setIsRevealPwd(prevState => !prevState)}
									/>
								}
							/>
							<ButtonContainer>
									<Button
										color={turquoise}
										hoverColor={lightGreen}
										css={"width: 60%; height: 40px;display: flex;justify-content: center;align-items: center;"}
										onClick={login}
									>
										{loading ? <FileLoader /> : 'Logga in'}
									</Button>
								</ButtonContainer>
							<CenterRow css={"margin-top: 20px"}>
								<InputLabel>Har du inget konto?</InputLabel>
								<ContactUsLink
								to={{ pathname: "https://www.eningo.se/contact" }}
								target="_blank" > Kontakta oss</ContactUsLink>
							</CenterRow>
							<FooterLinkContainer>
						{footerLinks.map((fl, i) => (
							<FooterLink key={i} to={{pathname: fl.link}} target="_blank">
								{fl.label}
							</FooterLink>
						))}
					</FooterLinkContainer>
						</LoginSubContainer>
					</LoginContainer>
				</Container>
			</OverlayLayer>
		</Background>
	);
};

export default LoginView;
