import styled from "styled-components";
import { Link } from "react-router-dom";
import {
	ScaleAnimation,
	ItemContainerAnimation,
} from "../../pandora/animations";
import { veryLightGrey, darkGreen } from "resources/newColors";

export const NavContainer = styled("div")<{
	selected?: boolean;
	color?: string;
	css?: string;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	padding: 10px 30px;
	transition: 0.3s ease;
	border-bottom: thin solid ${veryLightGrey};
	animation: ${ScaleAnimation} 0.3s linear 1;
	${(props) => props.css ?? ""}
`;

export const NavbarButtonContainer = styled(Link)<{
	hideBorder?: boolean;
	css?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: ${(props) =>
		props.hideBorder ? "none" : "thin solid rgba(255, 255, 255, 0.29)"};
	transition: 0.3s linear;
	animation: ${ItemContainerAnimation} 0.3s 1;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	${(props) => props.css ?? ""}
`;

export const NavbarButtonSubContainer = styled("div")<{
	selected?: boolean;
	color?: string;
	css?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) =>
		props.color
			? props.color
			: props.selected
			? "rgba(255, 255, 255, 0.29)"
			: "transparent"};
	padding: 10px;
	transition: 0.3s ease;
	animation: ${ScaleAnimation} 0.3s linear 1;
	${(props) => props.css ?? ""}
`;

export const NavbarButton = styled("img")<{ css?: string }>`
	height: auto;
	${(props) => props.css ?? ""}
`;

export const NavbarButtonLabelContainer = styled("div")<{ css?: string }>`
	display: flex;
	flex-direction: column;
	justitfy-content: center;
	${(props) => props.css ?? ""}
`;

export const NavbarButtonLabel = styled("span")<{
	fontWeight?: number;
	padding?: number;
	fontSize?: number;
	color?: string;
	css?: string;
}>`
	display: flex;
	align-items: center;
	color: ${(props) => props.color ?? darkGreen};
	font-size: ${(props) => props.fontSize ?? 18}px;
	font-weight: ${(props) => props.fontWeight ?? 900};
	padding: ${(props) => props.padding ?? 5}px;
	margin: 0 15px;
	${(props) => props.css ?? ""}
`;

export const PrefixIcon = styled("img")<{
	padding?: number;
	width?: number;
	backgroundColor?: string;
	css?: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${(props) => props.width ?? 25}px;
	height: auto;
	backgroundcolor: ${(props) => props.backgroundColor ?? "white"};
	padding: ${(props) => props.padding ?? 5}px;
	margin: 5px;
	${(props) => props.css ?? ""}
`;

export const SearchInputContainer = styled("span")<{
	fontWeight?: number;
	padding?: number;
	fontSize?: number;
	color?: string;
	css?: string;
}>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
	width: 200px;
	padding: ${(props) => props.padding ?? 5}px;
	margin: 10px 0px 10px 45px;
	border: 1px solid ${darkGreen};
	${(props) => props.css ?? ""};
`;


export const SearchInput = styled("input")<{
	fontWeight?: number;
	padding?: number;
	fontSize?: number;
	minWidth?: number;
	color?: string;
	css?: string;
}>`
	display: flex;
	align-items: center;
    min-width: ${(props) => props.minWidth ?? 200}px;
	width: 100%;
	color: ${(props) => props.color ?? darkGreen};
	font-size: ${(props) => props.fontSize ?? 12}px;
	font-weight: ${(props) => props.fontWeight ?? 500};
	padding: ${(props) => props.padding ?? 5}px;
	border: none;
    outline: none;
    ::placeholder {
		color: ${veryLightGrey};
        }px;
	${(props) => props.css ?? ""}
`;
