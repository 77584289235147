import  { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import {
	NavContainer,
	NavbarButtonContainer,
	NavbarButtonSubContainer,
	NavbarButton,
	NavbarButtonLabel,
} from "./styles/nav-style";
import BlockView from "resources/images/svg/block-view-green.svg";
import ListView from "resources/images/svg/list-view-green.svg";
import ColumnView from "resources/images/svg/column-view-green.svg";
import Switch from "resources/images/svg/switch-dark.svg";

const TopNav = ({ currentUserDetails }) => {
	const history = useHistory();
	const location = useLocation();
	const [selectedView, setSelectedView] = useState<string>("block");
	// const [notifications, setNotifications] = useState<
	// 	{ title: string; details: string }[]
	// >([]);

	useEffect(() => {
		setSelectedView(location.pathname);
	}, [location]);

	return (
		<NavContainer>
			<NavbarButtonContainer to={selectedView}>
				<NavbarButtonSubContainer
					selected={selectedView === "/" ? true : false}
				>
					<NavbarButton
						alt="block"
						src={
							selectedView === "/oversikt"
								? BlockView
								: selectedView === "/"
								? ListView
								: selectedView === "/organisation"
								? ColumnView
								: Switch
						}
					/>
					<NavbarButtonLabel>
						{selectedView === "/oversikt"
							? "Översikt"
							: selectedView === "/"
							? "Ärenden"
							: selectedView === "/organisation"
							? "Organisation"
							: "Profile"}
					</NavbarButtonLabel>
				</NavbarButtonSubContainer>
			</NavbarButtonContainer>
		</NavContainer>
	);
};

export default TopNav;
